import React from "react";
import SkwerLogo from "../../assets/img/skwer_b.png";
import "./Header.css";

function Header() {
  return (
    <header>
      <a className="logo-default dtr-scroll-link" href="#home">
        <img src={SkwerLogo} alt="skwer__logo" className="skwer__logo" />
      </a>
    </header>
  );
}

export default Header;
