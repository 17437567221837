import React from "react";

import CheckboxField from "../../CheckboxField/CheckboxField";

import "./TCDet.css";

import TCDoc from "../../../assets/pdf/terms&conditions.pdf";

function TCDet({
  formData,
  formData2,
  shareFormData,
  formData4,
  setFormData4,
}) {
  const handleConf1Change = (event) => {
    setFormData4({
      ...formData4,
      confirm_tc: event.target.checked,
    });
  };

  const handleConf2Change = (event) => {
    setFormData4({
      ...formData4,
      confirm_gen_tc_2: event.target.checked,
    });
  };

  const handleConf3Change = (event) => {
    setFormData4({
      ...formData4,
      confirm_3: event.target.checked,
    });
  };

  const handleConf4Change = (event) => {
    setFormData4({
      ...formData4,
      confirm_4: event.target.checked,
    });
  };

  return (
    <section className="step4">
      <h2>Declaration</h2>
      {/* <button onClick={sendEmail}>EMAIL</button> */}
      <div className="form_cont">
        {/* CHECKBOXES */}
        <section className="grid">
          <article>
            <CheckboxField
              label="I hereby confirm that I have read and I accept the attached Terms and Conditions"
              checked={formData4.confirm_tc}
              onChange={handleConf1Change}
              ahref={TCDoc}
              className="link"
              required="required"
            />
          </article>
          <article>
            <CheckboxField
              label="I, the General Manager, confirm that the information given in this form is true, complete and accurate."
              checked={formData4.confirm_gen_tc_2}
              onChange={handleConf2Change}
              required="required"
            />
          </article>
          <article>
            <CheckboxField
              label="The Licensee certifies that the Company Member(s) (shareholder(s)) described in the License Application Form is/are the UBO of the Licensee and if there is more than one Company Member, then the Company Members are the UBO in the same proportion as their shareholdings."
              checked={formData4.confirm_3}
              onChange={handleConf3Change}
              required="either"
            />
          </article>
          <article>
            <CheckboxField
              label="The Licensee certifies that the UBO is a person/entity who is not among the Company Member(s) (shareholder(s)) described in the License Application Form. The UBO's details and relationship to the Licensee will be provided separately by email."
              checked={formData4.confirm_4}
              onChange={handleConf4Change}
              required="either"
            />
          </article>
        </section>
      </div>
    </section>
  );
}

export default TCDet;
