import React from "react";

const CheckboxField = ({
  label,
  checked,
  onChange,
  ahref,
  name,
  className,
  required,
}) => {
  return (
    <div>
      <label>
        <input
          name={name}
          type="checkbox"
          checked={checked}
          onChange={onChange}
        />
        {ahref !== "" ? (
          <a
            href={ahref}
            target="_blank"
            rel="noreferrer"
            className={className}
          >
            {required === "required" ? (
              <span className="error">* </span>
            ) : required === "either" ? (
              <span className="error green_ask">* </span>
            ) : (
              ""
            )}
            {label}
          </a>
        ) : (
          { label }
        )}
      </label>
    </div>
  );
};

export default CheckboxField;
