import React from "react";

// import CheckboxField from "../../CheckboxField/CheckboxField";
// import InputField from "../../InputField/InputField";

import ShareHolderForm from "./ShareHolderForm";

import "./ShareHolderDet.css";

function ShareHolderDet({
  shareFormData,
  setShareFormData,
  formData,
  formData2,
}) {
  // console.log(formData3);
  return (
    <section className="step3">
      <h2>Shareholder/Member Details</h2>
      <div className="form_cont">
        <section>
          {/* Yellow Divider */}
          <section className="divider_note">
            {/* <h3>Add Company Shareholder</h3> */}
            <span>
              <strong>NOTE: </strong> <br />
              Your company should have <b>only one</b> General Manager,{" "}
              <b>only one</b> Secretary and <b>at least one</b> Director. You
              can assign all the previous roles to the same person by selecting
              all the boxes from the roles list in the form. Alternatively, you
              can add multiple individuals and select roles as required.
            </span>
          </section>
          <ShareHolderForm
            shareFormData={shareFormData}
            setShareFormData={setShareFormData}
            formData={formData}
            formData2={formData2}
          />
        </section>
      </div>
    </section>
  );
}

export default ShareHolderDet;
