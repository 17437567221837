import React, { useState } from "react";
import "./ApplicationDet.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";

import "react-phone-number-input/style.css";

import InputField from "../../InputField/InputField";
import SelectField from "../../SelectField/SelectField";

function ApplicationDet({ formData, setFormData }) {
  const phone = formatPhoneNumberIntl(formData.mobile_no);
  const [c_num, setCNum] = useState(phone);

  /* Validations */
  function isValidEmail(email) {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(email);
  }

  const optSalutation = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
  ];

  /*const optPMode = [
    { value: "Cash", label: "Cash" },
    { value: "Bank Transfer", label: "Bank Transfer" },
    { value: "Credit Card", label: "Credit Card" },
    { value: "Debit Card", label: "Debit Card" },
  ];*/

  const opt_type_comp = [
    { value: "Free Zone", label: "Free Zone" },
    { value: "Main Land", label: "Main Land" },
  ];

  /* Handle chages functions */
  const handleFnameChange = (event) => {
    setFormData({
      ...formData,
      f_name: event.target.value,
    });
  };

  const handleMnameChange = (event) => {
    setFormData({
      ...formData,
      m_name: event.target.value,
    });
  };

  const handleLnameChange = (event) => {
    setFormData({
      ...formData,
      l_name: event.target.value,
    });
  };

  const handleSaluChange = (event) => {
    setFormData({
      ...formData,
      salutation: event.target.value,
    });
  };

  const handleEAddChange = (event) => {
    setFormData({
      ...formData,
      email_add: event.target.value,
    });
  };

  const handleAppDateChange = (event) => {
    setFormData({
      ...formData,
      applicationDate: event.target.value,
    });
  };

  // const handlePModeChange = (event) => {
  //   setFormData({
  //     ...formData,
  //     payment_mode: event.target.value,
  //   });
  // };

  const handletypeCompChange = (event) => {
    setFormData({
      ...formData,
      type_comp: event.target.value,
    });
  };

  return (
    <section className="step1">
      <h2>Application Details</h2>
      <div className="form_cont">
        {/* Salutation, fname, mname, lname */}
        <section className="grid sec1">
          <article>
            <SelectField
              label="Salutation"
              value={formData.salutation}
              options={optSalutation}
              onChange={handleSaluChange}
              lblFor="salutation"
              id="salutation"
              classname="form-control"
              required="required"
            />
          </article>
          <article>
            <InputField
              label="First Name"
              type="text"
              lblFor="f_name"
              id="f_name"
              classname="form-control"
              value={formData.f_name}
              onChange={handleFnameChange}
              required="required"
            />
          </article>
          <article>
            <InputField
              label="Middle Name"
              type="text"
              lblFor="m_name"
              id="m_name"
              classname="form-control"
              value={formData.m_name}
              onChange={handleMnameChange}
            />
          </article>
          <article>
            <InputField
              label="Last Name"
              type="text"
              lblFor="l_name"
              id="l_name"
              classname="form-control"
              value={formData.l_name}
              onChange={handleLnameChange}
              required="required"
            />
          </article>
        </section>

        {/* mobile no., email */}
        <section className="grid sec2 mb-1 mt-1">
          <article>
            <span className="error">* </span>
            <label>Mobile Number</label>
            <PhoneInput
              className="phoneInput form-control"
              international
              defaultCountry=""
              value={c_num}
              onChange={(c_num) => {
                setCNum(c_num);
                setFormData({
                  ...formData,
                  mobile_no: formatPhoneNumberIntl(c_num),
                });
              }}
            />
            <span className="error">
              Phone Number :{" "}
              {c_num && isValidPhoneNumber(c_num) ? (
                <span className="txt_green"> Valid </span>
              ) : (
                <span className="txt_red"> Not valid </span>
              )}{" "}
            </span>
          </article>
          <article>
            <InputField
              label="E-mail Address"
              type="email"
              lblFor="email_add"
              id="email_add"
              classname="form-control"
              value={formData.email_add}
              onChange={handleEAddChange}
              required="required"
              width="96%"
            />
            <span className="error">
              {formData.email_add === ""
                ? " "
                : !isValidEmail(formData.email_add)
                ? " Invalid Email"
                : ""}
            </span>
          </article>
          {/* <article>
          <span>
            Please mention the email address where you would like receive the
            copy of this form as well as the updates regarding the application
            and quotation.
          </span>
        </article> */}
        </section>

        {/* Application date, Payment mode */}
        <section className="grid sec3">
          <article>
            <InputField
              label="Application Date"
              type="date"
              lblFor="applicationDate"
              id="applicationDate"
              classname="form-control"
              value={formData.applicationDate}
              onChange={handleAppDateChange}
              required="required"
            />
          </article>
          {/* <article>
            <SelectField
              label="How would you like to make the payment for the application?"
              value={formData.payment_mode}
              options={optPMode}
              onChange={handlePModeChange}
              lblFor="payment_mode"
              id="payment_mode"
              classname="form-control"
              required="required"
            />
          </article> */}
          <article>
            <SelectField
              label="What type of company you would like to set-up?"
              value={formData.type_comp}
              options={opt_type_comp}
              onChange={handletypeCompChange}
              lblFor="type_comp"
              id="type_comp"
              classname="form-control"
              required="required"
            />
          </article>
        </section>

        {/* STATEMENT */}
        <section className="statement">
          <i>
            Estimated time to complete the application is 3 minutes. All
            information entered on this form will be kept strictly confidential
            and subject to our Privacy Policy.
          </i>
        </section>
      </div>
    </section>
  );
}

export default ApplicationDet;
