
import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Main from './components/Main/Main';
import ThankYou from './components/Main/ThankYou/ThankYou';

function App() {
  return (
    <>
      <BrowserRouter basename='/' >
        <Routes>
          <Route path="/" element={<Main />} ></Route>
          <Route path="/thankyou" element={<ThankYou />} ></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
