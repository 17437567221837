import React from "react";

const InputField = ({
  label,
  type,
  value,
  onChange,
  lblFor,
  id,
  classname,
  name,
  required,
  placeholder,
  width,
  able,
}) => {
  return (
    <>
      {required === "required" ? <span className="error">* </span> : ""}
      <label htmlFor={lblFor}>{label}</label>

      <input
        type={type}
        value={value}
        onChange={onChange}
        id={id}
        className={classname}
        name={name}
        required={required}
        placeholder={placeholder}
        disabled={able}
        style={{ width: `${width}` }}
      />
    </>
  );
};

export default InputField;
