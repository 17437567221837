import React from "react";
import Header from "../../Header/Header";
import "./ThankYou.css";

function ThankYou() {
  return (
    <>
      <Header />
      <main className="grid step5">
        <section></section>
        <section className="thank_you_page">
          <h3>THANK YOU!</h3>
          <p>YOUR APPLICATION IS SUCCESSFULLY SUBMITTED.</p>

          <p>
            An email confirmation including the details about your application
            has been sent to the email address provided. Please keep it for your
            record. An account manager will be assigned to your order and will
            get in touch with you within the next 24 working hour.
          </p>
          {/* https://form.skwerzone.com/ http://localhost:3000/ */}
          <a href="https://www.skwergroup.com/" className="btn">
            BACK TO HOME PAGE
          </a>
        </section>
        <section></section>
      </main>
    </>
  );
}

export default ThankYou;
