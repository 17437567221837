export const country_list = [
  { value: "Afghanistan", label: "AF" },
  { value: "Land Islands", label: "AX" },
  { value: "Albania", label: "AL" },
  { value: "Algeria", label: "DZ" },
  { value: "American Samoa", label: "AS" },
  { value: "AndorrA", label: "AD" },
  { value: "Angola", label: "AO" },
  { value: "Anguilla", label: "AI" },
  { value: "Antarctica", label: "AQ" },
  { value: "Antigua and Barbuda", label: "AG" },
  { value: "Argentina", label: "AR" },
  { value: "Armenia", label: "AM" },
  { value: "Aruba", label: "AW" },
  { value: "Australia", label: "AU" },
  { value: "Austria", label: "AT" },
  { value: "Azerbaijan", label: "AZ" },
  { value: "Bahamas", label: "BS" },
  { value: "Bahrain", label: "BH" },
  { value: "Bangladesh", label: "BD" },
  { value: "Barbados", label: "BB" },
  { value: "Belarus", label: "BY" },
  { value: "Belgium", label: "BE" },
  { value: "Belize", label: "BZ" },
  { value: "Benin", label: "BJ" },
  { value: "Bermuda", label: "BM" },
  { value: "Bhutan", label: "BT" },
  { value: "Bolivia", label: "BO" },
  { value: "Bosnia and Herzegovina", label: "BA" },
  { value: "Botswana", label: "BW" },
  { value: "Bouvet Island", label: "BV" },
  { value: "Brazil", label: "BR" },
  { value: "British Indian Ocean Territory", label: "IO" },
  { value: "Brunei Darussalam", label: "BN" },
  { value: "Bulgaria", label: "BG" },
  { value: "Burkina Faso", label: "BF" },
  { value: "Burundi", label: "BI" },
  { value: "Cambodia", label: "KH" },
  { value: "Cameroon", label: "CM" },
  { value: "Canada", label: "CA" },
  { value: "Cape Verde", label: "CV" },
  { value: "Cayman Islands", label: "KY" },
  { value: "Central African Republic", label: "CF" },
  { value: "Chad", label: "TD" },
  { value: "Chile", label: "CL" },
  { value: "China", label: "CN" },
  { value: "Christmas Island", label: "CX" },
  { value: "Cocos (Keeling) Islands", label: "CC" },
  { value: "Colombia", label: "CO" },
  { value: "Comoros", label: "KM" },
  { value: "Congo", label: "CG" },
  { value: "Congo, The Democratic Republic of the", label: "CD" },
  { value: "Cook Islands", label: "CK" },
  { value: "Costa Rica", label: "CR" },
  { value: "Cote D Ivoire", label: "CI" },
  { value: "Croatia", label: "HR" },
  { value: "Cyprus", label: "CY" },
  { value: "Czech Republic", label: "CZ" },
  { value: "Denmark", label: "DK" },
  { value: "Djibouti", label: "DJ" },
  { value: "Dominica", label: "DM" },
  { value: "Dominican Republic", label: "DO" },
  { value: "Ecuador", label: "EC" },
  { value: "Egypt", label: "EG" },
  { value: "El Salvador", label: "SV" },
  { value: "Equatorial Guinea", label: "GQ" },
  { value: "Eritrea", label: "ER" },
  { value: "Estonia", label: "EE" },
  { value: "Ethiopia", label: "ET" },
  { value: "Falkland Islands (Malvinas)", label: "FK" },
  { value: "Faroe Islands", label: "FO" },
  { value: "Fiji", label: "FJ" },
  { value: "Finland", label: "FI" },
  { value: "France", label: "FR" },
  { value: "French Guiana", label: "GF" },
  { value: "French Polynesia", label: "PF" },
  { value: "French Southern Territories", label: "TF" },
  { value: "Gabon", label: "GA" },
  { value: "Gambia", label: "GM" },
  { value: "Georgia", label: "GE" },
  { value: "Germany", label: "DE" },
  { value: "Ghana", label: "GH" },
  { value: "Gibraltar", label: "GI" },
  { value: "Greece", label: "GR" },
  { value: "Greenland", label: "GL" },
  { value: "Grenada", label: "GD" },
  { value: "Guadeloupe", label: "GP" },
  { value: "Guam", label: "GU" },
  { value: "Guatemala", label: "GT" },
  { value: "Guernsey", label: "GG" },
  { value: "Guinea", label: "GN" },
  { value: "Guinea-Bissau", label: "GW" },
  { value: "Guyana", label: "GY" },
  { value: "Haiti", label: "HT" },
  { value: "Heard Island and Mcdonald Islands", label: "HM" },
  { value: "Holy See (Vatican City State)", label: "VA" },
  { value: "Honduras", label: "HN" },
  { value: "Hong Kong", label: "HK" },
  { value: "Hungary", label: "HU" },
  { value: "Iceland", label: "IS" },
  { value: "India", label: "IN" },
  { value: "Indonesia", label: "ID" },
  { value: "Iran, Islamic Republic Of", label: "IR" },
  { value: "Ireland", label: "IE" },
  { value: "Isle of Man", label: "IM" },
  { value: "Israel", label: "IL" },
  { value: "Italy", label: "IT" },
  { value: "Jamaica", label: "JM" },
  { value: "Japan", label: "JP" },
  { value: "Jersey", label: "JE" },
  { value: "Jordan", label: "JO" },
  { value: "Kazakhstan", label: "KZ" },
  { value: "Kenya", label: "KE" },
  { value: "Kiribati", label: "KI" },
  { value: "Korea, Republic of", label: "KR" },
  { value: "Kuwait", label: "KW" },
  { value: "Kyrgyzstan", label: "KG" },
  { value: "Lao People's Democratic Republic", label: "LA" },
  { value: "Latvia", label: "LV" },
  { value: "Lebanon", label: "LB" },
  { value: "Lesotho", label: "LS" },
  { value: "Liberia", label: "LR" },
  { value: "Libyan Arab Jamahiriya", label: "LY" },
  { value: "Liechtenstein", label: "LI" },
  { value: "Lithuania", label: "LT" },
  { value: "Luxembourg", label: "LU" },
  { value: "Macao", label: "MO" },
  { value: "Macedonia, The Former Yugoslav Republic of", label: "MK" },
  { value: "Madagascar", label: "MG" },
  { value: "Malawi", label: "MW" },
  { value: "Malaysia", label: "MY" },
  { value: "Maldives", label: "MV" },
  { value: "Mali", label: "ML" },
  { value: "Malta", label: "MT" },
  { value: "Marshall Islands", label: "MH" },
  { value: "Martinique", label: "MQ" },
  { value: "Mauritania", label: "MR" },
  { value: "Mauritius", label: "MU" },
  { value: "Mayotte", label: "YT" },
  { value: "Mexico", label: "MX" },
  { value: "Micronesia, Federated States of", label: "FM" },
  { value: "Moldova, Republic of", label: "MD" },
  { value: "Monaco", label: "MC" },
  { value: "Mongolia", label: "MN" },
  { value: "Montenegro", label: "ME" },
  { value: "Montserrat", label: "MS" },
  { value: "Morocco", label: "MA" },
  { value: "Mozambique", label: "MZ" },
  { value: "Myanmar", label: "MM" },
  { value: "Namibia", label: "NA" },
  { value: "Nauru", label: "NR" },
  { value: "Nepal", label: "NP" },
  { value: "Netherlands", label: "NL" },
  { value: "Netherlands Antilles", label: "AN" },
  { value: "New Caledonia", label: "NC" },
  { value: "New Zealand", label: "NZ" },
  { value: "Nicaragua", label: "NI" },
  { value: "Niger", label: "NE" },
  { value: "Nigeria", label: "NG" },
  { value: "Niue", label: "NU" },
  { value: "Norfolk Island", label: "NF" },
  { value: "Northern Mariana Islands", label: "MP" },
  { value: "Norway", label: "NO" },
  { value: "Oman", label: "OM" },
  { value: "Pakistan", label: "PK" },
  { value: "Palau", label: "PW" },
  { value: "Palestinian Territory, Occupied", label: "PS" },
  { value: "Panama", label: "PA" },
  { value: "Papua New Guinea", label: "PG" },
  { value: "Paraguay", label: "PY" },
  { value: "Peru", label: "PE" },
  { value: "Philippines", label: "PH" },
  { value: "Pitcairn", label: "PN" },
  { value: "Poland", label: "PL" },
  { value: "Portugal", label: "PT" },
  { value: "Puerto Rico", label: "PR" },
  { value: "Qatar", label: "QA" },
  { value: "Reunion", label: "RE" },
  { value: "Romania", label: "RO" },
  { value: "Russian Federation", label: "RU" },
  { value: "RWANDA", label: "RW" },
  { value: "Saint Helena", label: "SH" },
  { value: "Saint Kitts and Nevis", label: "KN" },
  { value: "Saint Lucia", label: "LC" },
  { value: "Saint Pierre and Miquelon", label: "PM" },
  { value: "Saint Vincent and the Grenadines", label: "VC" },
  { value: "Samoa", label: "WS" },
  { value: "San Marino", label: "SM" },
  { value: "Sao Tome and Principe", label: "ST" },
  { value: "Saudi Arabia", label: "SA" },
  { value: "Senegal", label: "SN" },
  { value: "Serbia", label: "RS" },
  { value: "Seychelles", label: "SC" },
  { value: "Sierra Leone", label: "SL" },
  { value: "Singapore", label: "SG" },
  { value: "Slovakia", label: "SK" },
  { value: "Slovenia", label: "SI" },
  { value: "Solomon Islands", label: "SB" },
  { value: "Somalia", label: "SO" },
  { value: "South Africa", label: "ZA" },
  { value: "South Georgia and the South Sandwich Islands", label: "GS" },
  { value: "Spain", label: "ES" },
  { value: "Sri Lanka", label: "LK" },
  { value: "Sudan", label: "SD" },
  { value: "Surivalue", label: "SR" },
  { value: "Svalbard and Jan Mayen", label: "SJ" },
  { value: "Swaziland", label: "SZ" },
  { value: "Sweden", label: "SE" },
  { value: "Switzerland", label: "CH" },
  { value: "Syrian Arab Republic", label: "SY" },
  { value: "Taiwan, Province of China", label: "TW" },
  { value: "Tajikistan", label: "TJ" },
  { value: "Tanzania, United Republic of", label: "TZ" },
  { value: "Thailand", label: "TH" },
  { value: "Timor-Leste", label: "TL" },
  { value: "Togo", label: "TG" },
  { value: "Tokelau", label: "TK" },
  { value: "Tonga", label: "TO" },
  { value: "Trinidad and Tobago", label: "TT" },
  { value: "Tunisia", label: "TN" },
  { value: "Turkey", label: "TR" },
  { value: "Turkmenistan", label: "TM" },
  { value: "Turks and Caicos Islands", label: "TC" },
  { value: "Tuvalu", label: "TV" },
  { value: "Uganda", label: "UG" },
  { value: "Ukraine", label: "UA" },
  { value: "United Arab Emirates", label: "AE" },
  { value: "United Kingdom", label: "GB" },
  { value: "United States", label: "US" },
  { value: "United States Minor Outlying Islands", label: "UM" },
  { value: "Uruguay", label: "UY" },
  { value: "Uzbekistan", label: "UZ" },
  { value: "Vanuatu", label: "VU" },
  { value: "Venezuela", label: "VE" },
  { value: "Viet Nam", label: "VN" },
  { value: "Virgin Islands, British", label: "VG" },
  { value: "Virgin Islands, U.S.", label: "VI" },
  { value: "Wallis and Futuna", label: "WF" },
  { value: "Western Sahara", label: "EH" },
  { value: "Yemen", label: "YE" },
  { value: "Zambia", label: "ZM" },
  { value: "Zimbabwe", label: "ZW" },
];
