import React, { useEffect } from "react";
import InputField from "../../InputField/InputField";
import SelectField from "../../SelectField/SelectField";

import "./CompanyDet.css";

function CompanyDet({ formData2, setFormData2 }) {
  /* Handle changes functions */
  const handleOpt1ENChange = (event) => {
    setFormData2({
      ...formData2,
      opt1_en: event.target.value,
    });
  };
  const handleOpt1ARChange = (event) => {
    setFormData2({
      ...formData2,
      opt1_ar: event.target.value,
    });
  };

  const handleOpt2ENChange = (event) => {
    setFormData2({
      ...formData2,
      opt2_en: event.target.value,
    });
  };

  const handleOpt2ARChange = (event) => {
    setFormData2({
      ...formData2,
      opt2_ar: event.target.value,
    });
  };

  const handleOpt3ENChange = (event) => {
    setFormData2({
      ...formData2,
      opt3_en: event.target.value,
    });
  };

  const handleOpt3ARChange = (event) => {
    setFormData2({
      ...formData2,
      opt3_ar: event.target.value,
    });
  };

  const handleLTypeChange = (event) => {
    setFormData2({
      ...formData2,
      lic_type: event.target.value,
    });
  };

  const handlePremisesChange = (event) => {
    setFormData2({
      ...formData2,
      premises: event.target.value,
    });
  };

  const handleTradeLicChange = (event) => {
    setFormData2({
      ...formData2,
      trade_lic: event.target.value,
    });
  };

  const handleVisaAlloChange = (event) => {
    setFormData2({
      ...formData2,
      visa_allo: event.target.value,
    });
  };

  const handleActDesc1Change = (event) => {
    setFormData2({
      ...formData2,
      act_desc1: event.target.value,
    });
  };

  const handleActDesc2Change = (event) => {
    setFormData2({
      ...formData2,
      act_desc2: event.target.value,
    });
  };

  const handleActDesc3Change = (event) => {
    setFormData2({
      ...formData2,
      act_desc3: event.target.value,
    });
  };

  const handleActDesc4Change = (event) => {
    setFormData2({
      ...formData2,
      act_desc4: event.target.value,
    });
  };

  const handleActDesc5Change = (event) => {
    setFormData2({
      ...formData2,
      act_desc5: event.target.value,
    });
  };

  const handleShareTypeChange = (event) => {
    setFormData2({
      ...formData2,
      share_type: event.target.value,
    });
  };

  const pro_share_cap_value = formData2.pro_share_cap;
  const share_val_value = formData2.share_val;

  useEffect(
    () => {
      const result = pro_share_cap_value / share_val_value;

      setFormData2({
        ...formData2,
        total_num_share: result,
      });
    }, // eslint-disable-next-line
    [share_val_value, pro_share_cap_value]
  );

  const handleProShareCapChange = (event) => {
    setFormData2({
      ...formData2,
      pro_share_cap: event.target.value,
    });
  };

  const handleShareValChange = (event) => {
    setFormData2({
      ...formData2,
      share_val: event.target.value,
    });
  };

  const optLType = [
    {
      value: "Commercial (Includes Trade)",
      label: "Commercial (Includes Trade)",
    },
    {
      value: "Professional (includes Services & Consultancy)",
      label: "Professional (includes Services & Consultancy)",
    },
    {
      value: "Combination (additional AED 2,500)",
      label: "Combination (additional AED 2,500)",
    },
  ];

  const optPremises = [
    { value: "Virtual Office", label: "Virtual Office" },
    { value: "Warehouse", label: "Warehouse" },
    { value: "Business Center", label: "Business Center" },
    { value: "Physical Office", label: "Physical Office" },
    { value: "Retail shop", label: "Retail shop" },
  ];

  const optVisaAllo = [
    {
      value: "0",
      label: "0",
    },
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
    {
      value: "4",
      label: "4",
    },
    {
      value: "5",
      label: "5",
    },
    {
      value: "6",
      label: "6",
    },
  ];

  const optTradeLic = [
    {
      value: "1 year",
      label: "1 year",
    },
    {
      value: "2 years",
      label: "2 years",
    },
    {
      value: "3 years",
      label: "3 years",
    },
    {
      value: "4 years",
      label: "4 years",
    },
  ];

  const optShareType = [
    {
      value: "Individual",
      label: "Individual",
    },
    {
      value: "Corporate",
      label: "Corporate",
    },
    {
      value: "Combination",
      label: "Combination",
    },
  ];

  // console.log(formData2);

  return (
    <section className="step2">
      <h2>Company Details</h2>
      <div className="form_cont">
        {/* Yellow Divider */}
        <section className="divider_note">
          <span>
            <strong>IMPORTANT:</strong> <br />
            Please make sure that you provide{" "}
            <b>
              three different company name options, in order of priority and
              most desired
            </b>
            . Your proposed company names should be{" "}
            <b>written exactly the way you want it to appear</b>. We will check
            the availability of the options provided in order. For example, if
            the first name is not available, we will check the second then
            third.{" "}
            <b>
              The company license will automatically be incorporated with the
              first approved name, in order of the options provided
            </b>
            . We will only revert to applicants if none of the names provided
            are available.
          </span>
        </section>

        {/* TITLE */}
        <section className="grid sec1 sec_title">
          <article></article>
          <article>
            <span className="bold">English Company Name</span>
            <span className="error"> * Required</span>
          </article>

          <article className="ar_comp_name">
            <span className="bold">Arabic Company Name</span>{" "}
            <span className="error"> (Optional)</span>
            <br />
            <i>
              If left blank, the authority will automatically provide a system
              generated Arabic translation of your company name.
            </i>
          </article>
        </section>

        {/* OPTION 1 */}
        <section className="grid sec1">
          <article>
            <span className="error">* </span>
            <span className="semi-bold">Option 1</span>
          </article>
          <article>
            <InputField
              type="text"
              lblFor="opt1_en"
              id="opt1_en"
              classname="form-control"
              value={formData2.opt1_en}
              onChange={handleOpt1ENChange}
              placeholder="English"
            />
          </article>

          <article>
            <InputField
              type="text"
              lblFor="opt1_ar"
              id="opt1_ar"
              classname="form-control cname_ar"
              value={formData2.opt1_ar}
              onChange={handleOpt1ARChange}
              placeholder="عربية"
            />
          </article>
        </section>

        {/* OPTION 2 */}
        <section className="grid sec1">
          <article>
            <span className="error">* </span>
            <span className="semi-bold">Option 2</span>
          </article>
          <article>
            <InputField
              type="text"
              lblFor="opt2_en"
              id="opt2_en"
              classname="form-control"
              value={formData2.opt2_en}
              onChange={handleOpt2ENChange}
              placeholder="English"
            />
          </article>

          <article>
            <InputField
              type="text"
              lblFor="opt2_ar"
              id="opt2_ar"
              classname="form-control cname_ar"
              value={formData2.opt2_ar}
              onChange={handleOpt2ARChange}
              placeholder="عربية"
            />
          </article>
        </section>

        {/* OPTION 3 */}
        <section className="grid sec1">
          <article>
            <span className="error">* </span>
            <span className="semi-bold">Option 3</span>
          </article>
          <article>
            <InputField
              type="text"
              lblFor="opt3_en"
              id="opt3_en"
              classname="form-control"
              value={formData2.opt3_en}
              onChange={handleOpt3ENChange}
              placeholder="English"
            />
          </article>

          <article>
            <InputField
              type="text"
              lblFor="opt3_ar"
              id="opt3_ar"
              classname="form-control cname_ar"
              value={formData2.opt3_ar}
              onChange={handleOpt3ARChange}
              placeholder="عربية"
            />
          </article>
        </section>

        {/* Yellow Divider */}
        <section className="divider_note mt-2">
          <h3>Select Your Business Activities</h3>
          <span>
            <strong>NOTE: </strong> <br />
            Business activities should be from one license type only. Certain
            activities are subject to third party approvals
          </span>
        </section>

        {/* LICENSE TYPE, TRADE LIC., VISA ALLO. */}
        <section className="grid sec2 mt-1">
          <article>
            <SelectField
              label="License Type"
              value={formData2.lic_type}
              options={optLType}
              onChange={handleLTypeChange}
              lblFor="lic_type"
              id="lic_type"
              classname="form-control"
              required="required"
            />
          </article>

          <article>
            <SelectField
              label="What type of premises would you require?"
              value={formData2.premises}
              options={optPremises}
              onChange={handlePremisesChange}
              lblFor="premises"
              id="premises"
              classname="form-control"
              required="required"
            />
          </article>

          <article>
            <SelectField
              label="Trade License Validity"
              value={formData2.trade_lic}
              options={optTradeLic}
              onChange={handleTradeLicChange}
              lblFor="trade_lic"
              id="trade_lic"
              classname="form-control"
              required="required"
            />
          </article>

          <article>
            <SelectField
              label="Visa Allocation"
              value={formData2.visa_allo}
              options={optVisaAllo}
              onChange={handleVisaAlloChange}
              lblFor="visa_allo"
              id="visa_allo"
              classname="form-control"
              required="required"
            />
          </article>
        </section>

        {/* ACTIVITIES */}
        <section className="grid sec1 sec1_act_title">
          <article></article>

          <article className="act_desc">
            <span className="bold">Activity Description</span>
          </article>
        </section>

        {/* ACTIVITY DESC 1 */}
        <section className="grid sec3">
          <article>
            <span className="error"> * </span>
            <span className="semi-bold">Activity 1</span>
          </article>

          <article>
            <InputField
              type="text"
              lblFor="act_desc1"
              id="act_desc1"
              classname="form-control"
              value={formData2.act_desc1}
              onChange={handleActDesc1Change}
            />
          </article>
        </section>

        {/* ACTIVITY DESC 2 */}
        <section className="grid sec3">
          <article>
            <span className="error"> * </span>
            <span className="semi-bold">Activity 2</span>
          </article>

          <article>
            <InputField
              type="text"
              lblFor="act_desc2"
              id="act_desc2"
              classname="form-control"
              value={formData2.act_desc2}
              onChange={handleActDesc2Change}
            />
          </article>
        </section>

        {/* ACTIVITY DESC 3 */}
        <section className="grid sec3 act_desc3">
          <article>
            <span className="error"> * </span>
            <span className="semi-bold">Activity 3</span>
          </article>

          <article>
            <InputField
              type="text"
              lblFor="act_desc3"
              id="act_desc3"
              classname="form-control"
              value={formData2.act_desc3}
              onChange={handleActDesc3Change}
            />
          </article>
        </section>

        <hr />
        <i>Additional AED 1,500 applicable</i>
        {/* ACTIVITY DESC 4 */}
        <section className="grid sec3">
          <article>
            <span className="semi-bold"> Activity 4</span>
          </article>

          <article>
            <InputField
              type="text"
              lblFor="act_desc4"
              id="act_desc4"
              classname="form-control"
              value={formData2.act_desc4}
              onChange={handleActDesc4Change}
            />
          </article>
        </section>

        {/* ACTIVITY DESC 5 */}
        <section className="grid sec3">
          <article>
            <span className="semi-bold"> Activity 5</span>
          </article>

          <article>
            <InputField
              type="text"
              lblFor="act_desc5"
              id="act_desc5"
              classname="form-control"
              value={formData2.act_desc5}
              onChange={handleActDesc5Change}
            />
          </article>
        </section>

        {/* Yellow Divider */}
        <section className="divider_note mt-2">
          <h3>Shareholding Type</h3>
          <span>
            <strong>NOTE: </strong> <br />
            Shareholding Type: If the shareholders are individuals, select the
            first option. If they are corporate, select the second. For a
            combination, select the third option.
          </span>
        </section>

        {/* SHAREHOLDING TYPE */}
        <section className="grid sec2 mt-1">
          <article>
            <SelectField
              label="Shareholding Type"
              value={formData2.share_type}
              options={optShareType}
              onChange={handleShareTypeChange}
              lblFor="share_type"
              id="share_type"
              classname="form-control"
              required="required"
            />
          </article>
        </section>

        {/* PROPOSED SHARE CAPITAL AED, SHARE VALU AED, TOTAL NUMBER OF SHARES*/}
        <section className="grid sec4 mt-1">
          <article>
            {/* <div className="tooltip">
              <span>tooltip</span>
              <div className="tooltip_content">This is tooltip content.</div>
            </div> */}
            <InputField
              label="Proposed Share Capital AED "
              type="number"
              lblFor="pro_share_cap"
              id="pro_share_cap"
              classname="form-control"
              value={formData2.pro_share_cap}
              onChange={handleProShareCapChange}
              required="required"
              placeholder="10000"
            />
            <span className="error">Minimum: AED 10,000</span>
          </article>

          <article>
            <InputField
              label="Share Value AED"
              type="number"
              lblFor="share_val"
              id="share_val"
              classname="form-control"
              value={formData2.share_val}
              onChange={handleShareValChange}
              required="required"
              placeholder="10"
            />
            <span className="error">Minimum: AED 10 and its multiples</span>
          </article>

          <article>
            <span className="error">* </span>
            <label>Company Total Number of Shares: </label> <br />
            <span className="total_num_share_data">
              {isNaN(formData2.total_num_share)
                ? "00"
                : isFinite(formData2.total_num_share)
                ? formData2.total_num_share
                : "00"}
            </span>
          </article>
        </section>
      </div>
    </section>
  );
}

export default CompanyDet;
