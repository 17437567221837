import React, { useState } from "react";
import Header from "../Header/Header";
import ApplicationDet from "./ApplicationDet/ApplicationDet";
import CompanyDet from "./CompanyDet/CompanyDet";
import ShareHolderDet from "./ShareHolderDet/ShareHolderDet";
import TCDet from "./TCDet/TCDet";
import Axios from "axios";
import { useNavigate } from "react-router-dom";

import Icon_pin from "../../assets/img/content-writing.png";

import "./Main.css";
import "./Steps.css";

function Main() {
  /* step titles */
  const StepTitles = [
    "Application Details",
    "Company Details",
    "Member Details",
    "Terms & Conditions and Declarations",
  ];

  // Function to get the current date in the required format
  function getCurrentDate() {
    const dateObj = new Date();
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  var today = getCurrentDate();

  const db_fields = {
    c_id: 0,
    salutation: "Mr.",
    f_name: "",
    m_name: "",
    l_name: "",
    mobile_no: "",
    email_add: "",
    applicationDate: today,
    /*payment_mode: "Cash",*/
    type_comp: "",
  };

  const [formData, setFormData] = useState(db_fields);

  /* C_ID */
  var c_id_temp = 0;
  function Random() {
    var maxNumber = 99;
    var randomNumber = Math.floor(Math.random() * maxNumber + 1);
    var randomNumber2 = Math.floor(Math.random() * maxNumber + 1);
    var today = new Date();
    var date = today.getFullYear();
    var final = date.toString().substring(2);
    c_id_temp = randomNumber + "" + randomNumber2 + final;

    return c_id_temp;
  }
  formData.c_id = formData.c_id !== 0 ? formData.c_id : Random();
  /* END  C_ID */

  const db_fields2 = {
    c_id: 0,
    opt1_en: "",
    opt1_ar: "",
    opt2_en: "",
    opt2_ar: "",
    opt3_en: "",
    opt3_ar: "",
    lic_type: "",
    premises: "",
    trade_lic: "",
    visa_allo: "",
    act_desc1: "",
    act_desc2: "",
    act_desc3: "",
    act_desc4: "",
    act_desc5: "",
    share_type: "",
    pro_share_cap: 0,
    share_val: 0,
    total_num_share: 0,
  };

  const [formData2, setFormData2] = useState(db_fields2);

  const [shareFormData, setShareFormData] = useState([
    {
      c_id: formData.c_id,
      share_gen_man_checked: false,
      share_sec_checked: false,
      share_direct_checked: false,
      share_shareholder_checked: false,
      share_shareholder_num: 0,
      share_salutation: "Ms.",
      share_f_name: "",
      share_m_name: "",
      share_l_name: "",
      share_gender: "Male",
      share_email: "",
      share_tele: "",
      share_mobile: "",
      share_passport_num: "",
      share_pass_issue_date: "",
      share_pass_expiry_date: "",
      share_passport_place_issue: "",
      share_pass_country_issue: "",
      share_country_birth: "",
      share_date_birth: "",
      share_nationality: "",
      share_address_line_1: "",
      share_address_line_2: "",
      share_po_box: "",
      share_city: "",
      share_state_province: "",
      share_country_add: "",
      share_father_full_name: "",
      share_mother_full_name: "",
    },
  ]);

  const db_fields4 = {
    c_id: 0,
    confirm_tc: false,
    confirm_gen_tc_2: false,
    confirm_3: false,
    confirm_4: false,
  };

  const [formData4, setFormData4] = useState(db_fields4);

  const [page, setPage] = useState(0);

  var step = 0;

  const StepContent = [
    <ApplicationDet
      page={page}
      setPage={setPage}
      formData={formData}
      setFormData={setFormData}
    />,
    <CompanyDet
      page={page}
      setPage={setPage}
      formData2={formData2}
      setFormData2={setFormData2}
    />,
    <ShareHolderDet
      page={page}
      setPage={setPage}
      formData={formData}
      formData2={formData2}
      shareFormData={shareFormData}
      setShareFormData={setShareFormData}
    />,
    <TCDet
      page={page}
      setPage={setPage}
      formData4={formData4}
      setFormData4={setFormData4}
      formData={formData}
      formData2={formData2}
      shareFormData={shareFormData}
    />,
  ];

  /* Validations */
  function isValidEmail(email) {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(email);
  }

  /* SAVING DATA via AXIOS */
  const handleSave1 = async () => {
    try {
      /* http://localhost:3001/saveData  https://skwermailapi.skwerzone.com/saveData*/
      const response = await Axios.post(
        "https://skwermailapi.skwerzone.com/saveData",
        {
          c_id: formData.c_id,
          salutation: formData.salutation,
          f_name: formData.f_name,
          m_name: formData.m_name,
          l_name: formData.l_name,
          mobile_no: formData.mobile_no,
          email_add: formData.email_add,
          applicationDate: formData.applicationDate,
          type_comp: formData.type_comp,
          date_created: today,
          opt1_en: formData2.opt1_en,
          opt1_ar: formData2.opt1_ar,
          opt2_en: formData2.opt2_en,
          opt2_ar: formData2.opt2_ar,
          opt3_en: formData2.opt3_en,
          opt3_ar: formData2.opt3_ar,
          lic_type: formData2.lic_type,
          premises: formData2.premises,
          trade_lic: formData2.trade_lic,
          visa_allo: formData2.visa_allo,
          act_desc1: formData2.act_desc1,
          act_desc2: formData2.act_desc2,
          act_desc3: formData2.act_desc3,
          act_desc4: formData2.act_desc4,
          act_desc5: formData2.act_desc5,
          share_type: formData2.share_type,
          pro_share_cap: formData2.pro_share_cap,
          share_val: formData2.share_val,
          total_num_share: formData2.total_num_share,
          confirm_tc: formData4.confirm_tc,
          confirm_gen_tc_2: formData4.confirm_gen_tc_2,
          confirm_3: formData4.confirm_3,
          confirm_4: formData4.confirm_4,
          data: shareFormData,
        }
      );

      console.log("Data saved:", response.data);
    } catch (error) {
      console.error("Error saving data:", error);
      alert("An error occurred while saving data");
    }
  };

  const navigate = useNavigate();

  const handleSubmit = () => {
    handleSave1();
    navigate("/thankyou");
  };

  return (
    <>
      <Header />
      <main className="flex main_thank_you">
        {/*==============LEFT SECTION=============*/}
        <section className="left_sec">
          <div className="left__steps__box">
            <div
              className={
                `step ${step === page ? "step-active " : null} ${
                  step < page ? "step-done " : ""
                }` + step
              }
            >
              <div>
                <div className="circle">
                  {step + 1 > page ? step + 1 : <i className="fa fa-check"></i>}
                </div>
              </div>
            </div>
            <div
              className={`step ${step + 1 === page ? "step-active " : null} ${
                step + 1 < page ? "step-done " : ""
              }`}
            >
              <div>
                <div className="circle">
                  {step + 1 === page || step + 1 > page ? (
                    step + 2
                  ) : (
                    <i className="fa fa-check"></i>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`step ${step + 2 === page ? "step-active " : null} ${
                step + 2 < page ? "step-done " : ""
              }`}
            >
              <div>
                <div className="circle">
                  {step + 2 === page || step + 2 > page ? (
                    step + 3
                  ) : (
                    <i className="fa fa-check"></i>
                  )}
                </div>
              </div>
            </div>
            <div
              className={`step ${step + 3 === page ? "step-active " : null} ${
                step + 3 < page ? "step-done " : ""
              }`}
            >
              <div>
                <div className="circle">
                  {step + 3 === page || step + 3 > page ? (
                    step + 4
                  ) : (
                    <i className="fa fa-check"></i>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        {/*==============CENTER SECTION=============*/}
        <section className="center_sec">
          <div>{StepContent[page]}</div>
          <section className="divider_note">
            <span>
              <strong>NOTE:</strong> <br />
              Please ensure that you complete all{" "}
              <b style={{ color: "#1475f7" }}>* </b> required fields.
              {page === StepTitles.length - 1 ? (
                <p>
                  <b className="green_ask">* </b> Select either of the two
                  options
                </p>
              ) : (
                ""
              )}
            </span>
          </section>
          <div className="np_buttons">
            <button
              className="btn"
              disabled={page === 0}
              onClick={() => {
                setPage((currPage) => currPage - 1);
              }}
            >
              PREVIOUS
            </button>
            <button
              className={page === StepTitles.length - 1 ? "btn hidden" : "btn"}
              //               page === 0 ||
              // (page === 1) &
              //   (formData.c_name1 === "" ||
              //     formData.c_name2 === "" ||
              //     formData.c_name3 === "") ||
              // (page === 4) &
              disabled={
                page === StepTitles.length - 1 ||
                (page === 0 &&
                  (formData.f_name === "" ||
                    formData.l_name === "" ||
                    formData.mobile_no === "" ||
                    formData.email_add === "" ||
                    formData.applicationDate === "" ||
                    formData.type_comp === "")) ||
                (page === 1 &&
                  (formData2.opt1_en === "" ||
                    formData2.opt2_en === "" ||
                    formData2.opt3_en === "" ||
                    formData2.lic_type === "" ||
                    formData2.premises === "" ||
                    formData2.trade_lic === "" ||
                    formData2.visa_allo === "" ||
                    formData2.act_desc1 === "" ||
                    formData2.act_desc2 === "" ||
                    formData2.act_desc3 === "" ||
                    formData2.share_type === "" ||
                    formData2.pro_share_cap === "" ||
                    formData2.share_val === "" ||
                    formData2.total_num_share === ""))
              }
              onClick={() => {
                if (page === StepTitles.length - 1) {
                } else {
                  setPage((currPage) => currPage + 1);
                }
              }}
            >
              NEXT
            </button>
            <button
              disabled={
                formData4.confirm_tc === false ||
                formData4.confirm_gen_tc_2 === false ||
                (formData4.confirm_3 === false && formData4.confirm_4 === false)
              }
              className={page === StepTitles.length - 1 ? "btn" : "btn hidden"}
              onClick={() => handleSubmit()}
            >
              SUBMIT
            </button>
          </div>
        </section>
        {/*==============RIGHT SECTION=============*/}
        <section className="right_sec right_summary">
          <span className="skwer__label">
            <img src={Icon_pin} alt="skwer_pin" />
            <h3 className="">Input Summary</h3>
          </span>
          <h4>Application Details</h4>
          <table>
            <tbody>
              <tr>
                <td>
                  <span>Name:</span>
                </td>
                <td>
                  {formData.f_name === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " +
                    formData.salutation +
                    " " +
                    formData.f_name +
                    " " +
                    formData.m_name +
                    " " +
                    formData.l_name
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Mobile No.:</span>
                </td>
                <td>
                  {formData.mobile_no === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData.mobile_no
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Email Address:</span>
                </td>
                <td>
                  {formData.email_add === "" ? (
                    <span className="error">* Required field</span>
                  ) : !isValidEmail(formData.email_add) ? (
                    <span>* Invalid Email</span>
                  ) : (
                    " " + formData.email_add
                  )}
                </td>
              </tr>
              <tr>
                <td>Application Date: </td>
                <td>
                  {formData.applicationDate === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    formData.applicationDate
                  )}
                </td>
              </tr>
              <tr>
                <td>Type of Company: </td>
                <td>
                  {formData.type_comp === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    formData.type_comp
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <h4>Company Details</h4>
          <span>Company Name</span>
          <table>
            <tbody>
              <tr>
                <td>
                  <span>Option 1:</span>
                </td>
                <td>
                  {formData2.opt1_en === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.opt1_en
                  )}

                  {formData2.opt1_ar === "" ? "" : " - " + formData2.opt1_ar}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Option 2:</span>
                </td>
                <td>
                  {formData2.opt2_en === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.opt2_en
                  )}

                  {formData2.opt2_ar === "" ? "" : " - " + formData2.opt2_ar}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Option 3:</span>
                </td>
                <td>
                  {formData2.opt3_en === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.opt3_en
                  )}

                  {formData2.opt3_ar === "" ? "" : " - " + formData2.opt3_ar}
                </td>
              </tr>
            </tbody>
          </table>
          <span>Business Activities</span>
          <table>
            <tbody>
              <tr>
                <td>
                  <span>License Type: </span>
                </td>
                <td>
                  {formData2.lic_type === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.lic_type
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Premises: </span>
                </td>
                <td>
                  {formData2.premises === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.premises
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Trade License Validity: </span>
                </td>
                <td>
                  {formData2.trade_lic === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.trade_lic
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Visa Allocation: </span>
                </td>
                <td>
                  {formData2.visa_allo === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.visa_allo
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Activity 1: </span>
                </td>
                <td>
                  {formData2.act_desc1 === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.act_desc1
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Activity 2: </span>
                </td>
                <td>
                  {formData2.act_desc2 === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.act_desc2
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Activity 3: </span>
                </td>
                <td>
                  {formData2.act_desc3 === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.act_desc3
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Activity 4: </span>
                </td>
                <td>
                  {formData2.act_desc1 === "" ? "" : " " + formData2.act_desc4}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Activity 5: </span>
                </td>
                <td>
                  {formData2.act_desc5 === "" ? "" : " " + formData2.act_desc5}
                </td>
              </tr>
            </tbody>
          </table>
          <span>Shareholding Type</span>
          <table>
            <tbody>
              <tr>
                <td>
                  <span>Shareholding Type:</span>
                </td>
                <td>
                  {formData2.share_type === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.share_type
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Proposed Share Capital AED:</span>
                </td>
                <td>
                  {formData2.pro_share_cap === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.pro_share_cap
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Share Value AED:</span>
                </td>
                <td>
                  {formData2.share_val === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.share_val
                  )}
                </td>
              </tr>
              <tr>
                <td>
                  <span>Total Number of Shares:</span>
                </td>
                <td>
                  {formData2.total_num_share === "" ? (
                    <span className="error">* Required field</span>
                  ) : (
                    " " + formData2.total_num_share
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <h4>Shareholder/Member Details</h4>
          {shareFormData.map((item, index) => {
            return (
              <div key={index}>
                <p className="share_title">Company Member {index + 1}</p>
                <table>
                  <tbody>
                    <tr>
                      <td>Role:</td>
                      <td>
                        {item.share_gen_man_checked === true
                          ? "General Manager/Representative, "
                          : ""}
                        {item.share_sec_checked === true ? "Secretary, " : ""}
                        {item.share_direct_checked === true ? "Director, " : ""}
                        {item.share_shareholder_checked === true
                          ? "Shareholder ( " + item.share_shareholder_num + " )"
                          : ""}
                      </td>
                    </tr>
                    <tr>
                      <td>Name:</td>
                      <td>
                        {item.share_salutation +
                          " " +
                          item.share_f_name +
                          " " +
                          item.share_m_name +
                          " " +
                          item.share_l_name}
                      </td>
                    </tr>
                    <tr>
                      <td>Gender:</td>
                      <td>{item.share_gender}</td>
                    </tr>
                    <tr>
                      <td>Email Address:</td>
                      <td>{item.share_email}</td>
                    </tr>
                    <tr>
                      <td>Telephone No.:</td>
                      <td>{item.share_tele}</td>
                    </tr>
                    <tr>
                      <td>Mobile No.:</td>
                      <td>{item.share_mobile}</td>
                    </tr>
                    <tr>
                      <td className="share_subtitle"></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Passport No.:</td>
                      <td>{item.share_passport_num}</td>
                    </tr>
                    <tr>
                      <td>Issue Date:</td>
                      <td>{item.share_pass_issue_date}</td>
                    </tr>
                    <tr>
                      <td>Expiry Date:</td>
                      <td>{item.share_pass_expiry_date}</td>
                    </tr>
                    <tr>
                      <td className="share_subtitle"></td>
                      <td></td>
                    </tr>
                    <tr>
                      <td>Country of Birth:</td>
                      <td>{item.share_country_birth}</td>
                    </tr>
                    <tr>
                      <td>Date of Birth:</td>
                      <td>{item.share_date_birth}</td>
                    </tr>
                    <tr>
                      <td>Nationality:</td>
                      <td>{item.share_nationality}</td>
                    </tr>
                    <tr>
                      <td>Address Line 1:</td>
                      <td>{item.share_address_line_1}</td>
                    </tr>
                    <tr>
                      <td>Address Line 2:</td>
                      <td>{item.share_address_line_2}</td>
                    </tr>
                    <tr>
                      <td>PO Box / Postal Code:</td>
                      <td>{item.share_po_box}</td>
                    </tr>
                    {/* <tr>
                      <td>Postal Code:</td>
                      <td>{item.share_postal_code}</td>
                    </tr> */}
                    <tr>
                      <td>City:</td>
                      <td>{item.share_city}</td>
                    </tr>
                    <tr>
                      <td>State Province:</td>
                      <td>{item.share_state_province}</td>
                    </tr>
                    <tr>
                      <td>Country:</td>
                      <td>{item.share_country_add}</td>
                    </tr>
                    <tr>
                      <td>Father's Full Name:</td>
                      <td>{item.share_father_full_name}</td>
                    </tr>
                    <tr>
                      <td>Mother's Full Name:</td>
                      <td>{item.share_mother_full_name}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            );
          })}
        </section>
      </main>
    </>
  );
}

export default Main;
