import React from "react";

const SelectField = ({
  label,
  value,
  options,
  onChange,
  lblFor,
  id,
  classname,
  name,
  width,
  required,
}) => {
  return (
    <>
      {required === "required" ? <span className="error">* </span> : ""}
      <label htmlFor={lblFor}>{label}</label>
      <select
        value={value}
        onChange={onChange}
        id={id}
        className={classname}
        name={name}
        required={required}
        style={{ width: `${width}` }}
      >
        <option hidden>-- SELECT --</option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.value}
          </option>
        ))}
      </select>
    </>
  );
};

export default SelectField;
