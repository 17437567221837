import React from "react";

import InputField from "../../InputField/InputField";
import SelectField from "../../SelectField/SelectField";
import CheckboxField from "../../CheckboxField/CheckboxField";

import { country_list } from "./country_list";

/*import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
} from "react-phone-number-input";*/

import "react-phone-number-input/style.css";

const ShareHolderForm = ({
  shareFormData,
  setShareFormData,
  formData,
  formData2,
}) => {
  /*const phone = formatPhoneNumberIntl(formData.mobile_no);
  const [c_num, setCNum] = useState(phone);*/

  const handleInputChange = (index, event) => {
    const { name, value, type, checked } = event.target;
    const newValue = type === "checkbox" ? checked : value;
    const newFormData = [...shareFormData];
    newFormData[index][name] = newValue;
    setShareFormData(newFormData);
    // console.log("Form Data:", shareFormData);
  };

  const handleAddFields = () => {
    setShareFormData([
      ...shareFormData,
      {
        c_id: formData.c_id,
        share_gen_man_checked: false,
        share_sec_checked: false,
        share_direct_checked: false,
        share_shareholder_checked: false,
        share_shareholder_num: 0,
        share_salutation: "Ms.",
        share_f_name: "",
        share_m_name: "",
        share_l_name: "",
        share_gender: "",
        share_email: "",
        share_tele: "",
        share_mobile: "",
        share_passport_num: "",
        share_pass_issue_date: "",
        share_pass_expiry_date: "",
        share_passport_place_issue: "",
        share_pass_country_issue: "",
        share_country_birth: "",
        share_date_birth: "",
        share_nationality: "",
        share_address_line_1: "",
        share_address_line_2: "",
        share_po_box: "",
        share_city: "",
        share_state_province: "",
        share_country_add: "",
        share_father_full_name: "",
        share_mother_full_name: "",
      },
    ]);
  };

  const optSalutation = [
    { value: "Mr.", label: "Mr." },
    { value: "Ms.", label: "Ms." },
    { value: "Mrs.", label: "Mrs." },
  ];

  const optGender = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  /* Email Validations */
  function isValidEmail(email) {
    return /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/i.test(email);
  }

  const handleRemoveFields = (index) => {
    const newFormData = [...shareFormData];
    newFormData.splice(index, 1);
    setShareFormData(newFormData);
  };

  return (
    <>
      {shareFormData.map((field, index) => (
        <div key={index} className="shareholder_form_container">
          <section className="title_remove">
            <h3>Role(s)</h3>
            <button
              type="button"
              onClick={() => handleRemoveFields(index)}
              className="shareholder_button"
            >
              x
            </button>
          </section>
          <section className="grid sec1 align_middle">
            <article>
              <CheckboxField
                name="share_gen_man_checked"
                label="General Manager/Representative"
                checked={field.share_gen_man_checked}
                onChange={(event) => handleInputChange(index, event)}
              />
            </article>
            <article>
              <CheckboxField
                name="share_sec_checked"
                label="Secretary"
                checked={field.share_sec_checked}
                onChange={(event) => handleInputChange(index, event)}
              />
            </article>
            <article>
              <CheckboxField
                name="share_direct_checked"
                label="Director"
                checked={field.share_direct_checked}
                onChange={(event) => handleInputChange(index, event)}
              />
            </article>
            <article>
              <CheckboxField
                name="share_shareholder_checked"
                label="Shareholder"
                checked={field.share_shareholder_checked}
                onChange={(event) => handleInputChange(index, event)}
              />
            </article>
            <article>
              <InputField
                name="share_shareholder_num"
                label="If Shareholder, please indicate how many shares"
                type="number"
                classname="form-control w-10"
                value={field.share_shareholder_num}
                onChange={(event) => handleInputChange(index, event)}
              />
            </article>
            <article>
              <InputField
                name="share_shareholder_num"
                label="Company Total Number of Shares:"
                type="number"
                classname="form-control w-10 total_num_share_data_2"
                value={formData2.total_num_share}
                able="disable"
              />
            </article>
            {/* <article>
              <span className="error">* </span>
              <label>Company Total Number of Shares: </label>
              <span className="total_num_share_data">
                {isNaN(formData2.total_num_share)
                  ? "00"
                  : isFinite(formData2.total_num_share)
                  ? formData2.total_num_share
                  : "00"}
              </span>
            </article> */}
          </section>
          <h3>Full Name</h3>
          {/* SALUTATION, FNAME ... */}
          <section className="grid sec1">
            <article>
              <SelectField
                name="share_salutation"
                label="Salutation"
                value={field.salutation}
                options={optSalutation}
                onChange={(event) => handleInputChange(index, event)}
                classname="form-control"
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_f_name"
                label="First Name"
                type="text"
                classname="form-control"
                value={field.share_f_name}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_m_name"
                label="Middle Name"
                type="text"
                classname="form-control"
                value={field.share_m_name}
                onChange={(event) => handleInputChange(index, event)}
              />
            </article>
            <article>
              <InputField
                name="share_l_name"
                label="Last Name"
                type="text"
                classname="form-control"
                value={field.share_l_name}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
          </section>

          {/* GENDER, EMAIL, TELE, MOBIL */}
          <section className="grid sec1 mt-1">
            <article>
              <SelectField
                name="share_gender"
                label="Gender"
                value={field.share_gender}
                options={optGender}
                onChange={(event) => handleInputChange(index, event)}
                classname="form-control"
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_email"
                label="E-mail Address"
                type="email"
                classname="form-control"
                value={field.share_email}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
              <span className="error">
                {field.share_email === ""
                  ? " "
                  : !isValidEmail(field.share_email)
                  ? " Invalid Email"
                  : ""}
              </span>
            </article>
            <article>
              <InputField
                name="share_tele"
                label="Telephone"
                type="text"
                classname="form-control"
                value={field.share_tele}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            {/* <article>
              <span className="error">* </span>
              <label>Telephone</label>

              <PhoneInput
                className="phoneInput form-control"
                international
                defaultCountry=""
                value={c_num}
                onChange={(event) => {
                  setCNum(event);
                  // setFormData({
                  //   ...formData,
                  //   share_tele: formatPhoneNumberIntl(c_num),
                  // });
                  handleInputChange(index, event);
                }}
              />
              <span className="error">
                Phone Number :{" "}
                {c_num && isValidPhoneNumber(c_num) ? (
                  <span className="txt_green"> Valid </span>
                ) : (
                  <span className="txt_red"> Not valid </span>
                )}{" "}
              </span>
            </article> */}
            <article>
              <InputField
                name="share_mobile"
                label="Mobile Number"
                type="text"
                classname="form-control"
                value={field.share_mobile}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
          </section>

          {/* PASSPORT DET */}
          <section className="grid sec1 mt-1">
            <article>
              <article></article>
              <InputField
                name="share_passport_num"
                label="Passport Number"
                type="text"
                classname="form-control"
                value={field.share_passport}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_pass_issue_date"
                label="Passport Issue Date"
                type="date"
                classname="form-control"
                value={field.share_pass_issue_date}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_pass_expiry_date"
                label="Passport Expiry Date"
                type="date"
                classname="form-control"
                value={field.share_pass_expiry_date}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_passport_place_issue"
                label="Place of Issue"
                type="text"
                classname="form-control"
                value={field.share_passport_place_issue}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <SelectField
                name="share_pass_country_issue"
                label="Passport Country of Issue"
                value={field.share_pass_country_issue}
                options={country_list}
                onChange={(event) => handleInputChange(index, event)}
                classname="form-control"
                required="required"
                width="105%"
              />
            </article>
          </section>

          {/* COUNTRY OF BIRTH */}
          <section className="grid sec1 mt-1">
            <article>
              <SelectField
                name="share_country_birth"
                label="Country of Birth"
                value={field.share_country_birth}
                options={country_list}
                onChange={(event) => handleInputChange(index, event)}
                classname="form-control"
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_date_birth"
                label="Date of Birth"
                type="date"
                classname="form-control"
                value={field.share_date_birth}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <SelectField
                name="share_nationality"
                label="Current Nationality"
                value={field.share_nationality}
                options={country_list}
                onChange={(event) => handleInputChange(index, event)}
                classname="form-control"
                required="required"
              />
            </article>
          </section>

          <h3>Full Address</h3>
          {/* ADDRESS */}
          <section className="grid sec1">
            <article>
              <InputField
                name="share_address_line_1"
                label="Address Line 1"
                type="text"
                classname="form-control"
                value={field.share_address_line_1}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_address_line_2"
                label="Address Line 2"
                type="text"
                classname="form-control"
                value={field.share_address_line_2}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_po_box"
                label="PO Box / Postal Code"
                type="text"
                classname="form-control"
                value={field.share_po_box}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            {/* <article>
              <InputField
                name="share_postal_code"
                label="Postal Code"
                type="text"
                classname="form-control"
                value={field.share_postal_code}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article> */}
            <article>
              <InputField
                name="share_city"
                label="City"
                type="text"
                classname="form-control"
                value={field.share_city}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_state_province"
                label="State Province"
                type="text"
                classname="form-control"
                value={field.share_state_province}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <SelectField
                name="share_country_add"
                label="Country"
                value={field.share_country_add}
                options={country_list}
                onChange={(event) => handleInputChange(index, event)}
                classname="form-control"
                required="required"
              />
            </article>
          </section>

          {/* PARENTS */}
          <section className="grid sec2 mt-2">
            <article>
              <InputField
                name="share_father_full_name"
                label="Father's Full Name"
                type="text"
                classname="form-control"
                value={field.share_father_full_name}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
              />
            </article>
            <article>
              <InputField
                name="share_mother_full_name"
                label="Mother's Full Name"
                type="text"
                classname="form-control"
                value={field.share_mother_full_name}
                onChange={(event) => handleInputChange(index, event)}
                required="required"
                width="97.5%"
              />
            </article>
          </section>
        </div>
      ))}
      <button
        type="button"
        onClick={handleAddFields}
        className="shareholder_button"
      >
        + Add More
      </button>
    </>
  );
};

export default ShareHolderForm;
